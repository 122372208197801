<template>
  <div class="flex flex-col">
    <ArenaGenericLayout
      :show-altinget-logo="true"
      :show-mm-logo="true"
      :topbar-title="$t('ads.sectionTitle')"
      :title="$t('ads.attentionMoney')"
      :description="$t('ads.attentionDemand')"
      :picture-url="$t('ads.image')"
    >
      <h2 class="headline-xs">{{ $t('ads.contactUs') }}</h2>
      <div class="mt-6 -mx-4 md:mx-0">
        <CommonDivider />
        <div class="flex flex-row items-center">
          <CommonFontAwesomeIcon
            class="px-4 text-xl text-gray-500"
            icon="fa-regular fa-phone"
          />
          <div class="flex flex-col">
            <span class="text-gray-500">{{ $t('ads.phone') }}</span>
            <a :href="`tel:${$t('ads.phoneNumberFull')}`">{{
              $t('ads.phoneNumberWithSpaces')
            }}</a>
          </div>
        </div>
        <CommonDivider />
        <div class="flex flex-row items-center">
          <CommonFontAwesomeIcon
            class="px-4 text-xl text-gray-500"
            icon="fa-regular fa-envelope"
          />
          <div class="flex flex-col">
            <span class="text-gray-500">{{ $t('ads.email') }}</span>
            <a :href="`mailto:${$t('ads.siteEmail')}`">{{
              $t('ads.siteEmail')
            }}</a>
          </div>
        </div>
        <CommonDivider />
      </div>
      <article class="whitespace-pre-line"></article>
      <AdsSectionsDK v-if="true" />
      <AdsSectionsSE v-else-if="false" />
      <AdsSectionsNO v-else-if="false" />
      <template #right>
        <ArenaSignup
          :title="$t('ads.knowMore')"
          :description="$t('ads.priceContactUs')"
          :person="contactPerson"
          :submit-lead="submitContactModal"
          :secondary-button-action="() => (showContactModal = true)"
        >
          <template v-slot:mainButtonContent>
            <a
              class="flex items-center justify-center gap-2 leading-10"
              :href="`tel:${$t('ads.phoneNumberFull')}`"
            >
              <CommonFontAwesomeIcon
                class="text-white mr-2!align-middle"
                icon="fa-solid fa-phone"
              />
              {{ $t('ads.phoneNumberWithSpaces') }}</a
            >
          </template>
          <template v-slot:secondaryButtonContent>{{ $t('CallMe') }}</template>
        </ArenaSignup>
      </template>
    </ArenaGenericLayout>
    <div class="container my-28">
      <div
        class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:justify-between md:space-x-4"
      >
        <AdsThreeReasonsDK v-if="true" />
        <AdsThreeReasonsSE v-else-if="false" />
        <AdsThreeReasonsNO v-else-if="false" />
      </div>
    </div>

    <ArenaContactModal
      v-if="showContactModal"
      @modal-close="showContactModal = false"
      :submit-lead="submitContactModal"
    />
    <!-- MOBILE -->
    <div
      class="fixed bottom-0 sm:hidden shadow-t-md w-full bg-white z-[60] h-17 flex items-center justify-between px-3"
    >
      <div class="flex flex-row w-full space-x-4">
        <button
          class="flex items-center justify-center mr-2 bg-gray-300 rounded-full h-9 w-9"
          @click="showContactModal = true"
        >
          <CommonFontAwesomeIcon
            class="text-sm text-gray-700"
            icon="fa-regular fa-info-circle"
          />
        </button>
        <CommonButton
          bg="bg-blue"
          text="text-white"
          border="border-none"
          class="flex-1"
        >
          <a
            class="block w-full h-full text-center leading-9"
            :href="`tel:${$t('ads.phoneNumberFull')}`"
          >
            <CommonFontAwesomeIcon icon="fa-solid fa-phone" class="mr-3" />
            {{ $t('ads.phoneNumberWithSpaces') }}</a
          >
        </CommonButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { HotshotUser, Lead } from '~/typesAuto/apicore/v1'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

useHead({
  title: nuxtApp.$t('ads.title'),
  meta: [
    {
      name: 'og:description',
      hid: 'og:description',
      content: nuxtApp.$t('ads.metaDescription'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: nuxtApp.$t('ads.title'),
    },
    {
      name: 'description',
      hid: 'description',
      content: nuxtApp.$t('ads.metaDescription'),
    },
    {
      name: 'title',
      hid: 'title',
      content: nuxtApp.$t('ads.title'),
    },
  ],
})

const showContactModal = ref(false)
const contactPerson = {
  mobile: nuxtApp.$t('ads.phoneNumberFull'),
  email: nuxtApp.$t('ads.contactEmail'),
  name: false ? nuxtApp.$t('ads.contactName') : undefined,
  title: false ? nuxtApp.$t('ads.contactTitle') : undefined,
  image:
    config.public.site.locale === 'se'
      ? 'https://legacy.altinget.se/images/Writers/111-carl-eyton-2-96.jpg'
      : undefined,
} as HotshotUser

const submitContactModal = async (lead: Lead): Promise<boolean> => {
  try {
    await nuxtApp.$api.leads.submitAdsLead(lead)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
</script>
